<template>
  <div class="container">
    <div
        class="row justify-content-center align-items-center mt-5 mb-5"
        style="min-height: calc(100vh - 6rem)"
    >
      <div class="col-12 col-sm" style="max-width: 600px">
        <h5 class="mb-3">โปรดตรวจสอบรายละเอียดคำสั่งซื้อให้ถูกต้อง</h5>

        <div class="card mb-5">
          <div class="card-body">
            <div class="font-weight-bold">รายละเอียดคำสั่งซื้อ</div>

            <div class="row">
              <div class="col text-secondary">ชื่อ-นามสกุล</div>
              <div class="col text-right">{{ user.name }}</div>
            </div>

            <div class="row">
              <div class="col text-secondary">อีเมล</div>
              <div class="col text-right">{{ user.email }}</div>
            </div>

            <div class="row mb-3">
              <div class="col text-secondary">เบอร์โทรศัพท์</div>
              <div class="col text-right">{{ user.phone }}</div>
            </div>

            <div class="row no-gutters mb-3" style="border: 1px solid black">
              <div class="col-12  col-sm-4">
                <div class="card-body ">
                  <img class="img-fluid" :src="item.url_image"/>
                </div>
              </div>
              <div class="col">
                <div class="card-body">
                  <div>
                    {{ item.name }}
                  </div>
                  <div class="text-secondary" v-html="item.detail"></div>

                  <div class="row mt-5">
                    <div class="col">฿{{ item.price | number }}</div>
                    <div class="col-auto">x {{ qty }} ชิ้น</div>
                  </div>

                  <div class="row font-weight-bold">
                    <div class="col">ยอดชำระทั้งหมด</div>
                    <div class="col-auto"> {{ item.price * qty | number }} บาท</div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="font-weight-bold mb-3 mt-3">
          กรุณาเลือกวิธีการชำระเงิน
        </div>

        <label class="mb-3" v-for="data in item.gateway">
          <input
              type="radio"
              v-model="paymentMethod"
              :value="data.gateway_key"
              class="card-input-element d-none"
              value="qr_code"
          />
          <span class="card card-body bg-light d-flex flex-row align-items-center">
            <img
                class="img-fluid mr-3"
                style="max-height: 25px"
                :src="require(`@/assets/image/${data.gateway_key}.png`)"
            />
            {{ data.gateway_name }}
          </span>
        </label>


        <div class="credit-card mb-3" v-if="this.paymentMethod === 'gbp_credit_card'">
          <label class="body mb-3" v-for="card in gbp.cards">
            <input
                type="radio"
                v-model="selectedCard"
                :value="card"
                @change="selectCard(selectedCard)"
                class="radio"/>
            <span class="list card-body border-0 bg-light d-flex flex-row align-items-center">
            <div class="col pl-5">
              **** **** **** {{ card.card_number.slice(-4) }}
            </div>
             <div class="col-auto">
               <img class="img-fluid"
                    style="max-height: 25px"
                    :src="require(`@/assets/image/gbp_credit_card.png`)"/>
             </div>
          </span>
          </label>

          <label class="body mb-3">
            <input
                type="radio"
                v-model="selectedCard"
                :value="{}"
                @change="selectCard(null)"
                class="radio"/>
            <span class="list card-body border-0 bg-light d-flex flex-row align-items-center">
            <div class="col pl-5">
              เพิ่มบัตรใหม่
            </div>
          </span>
          </label>
        </div>

        <error-message class="mt-1" :msg="errorMessage.paymentMethod"></error-message>

        <button class="mb-3 mt-3 w-100 btn btn-primary" @click="createTransaction" :disabled="item.gateway.length <= 0">
          ชำระเงิน
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store";
import {mapState} from "vuex";
import {GENERATE_TRANSACTION, GENERATE_TRANSACTION_GBP, GET_CARD_TOKEN} from "../store/actions.type";
import Swal from "sweetalert2";
import validate from "validate.js";
import ErrorMessage from "../components/ErrorMessage";

export default {
  name: "Home",
  components: {ErrorMessage},
  computed: {
    ...mapState({
      item: (state) => state.payment.itemInfo,
      transactionId: (state) => state.payment.transactionId,
      user: (state) => state.payment.userInfo,
      rpId: state => state.payment.rpId,
      eUid: state => state.payment.eUid,
      originalName: state => state.payment.originalName,
      gbp: state => state.gbp,
      qty: state => state.payment.qty,
    }),
  },
  data() {
    return {
      paymentMethod: "",
      errorMessage: {},
      selectedCard: {}
    }
  },
  async created() {
    if (!this.item.unique_id) {
      await this.$router.push("/message");
    }

    // auto set payment method
    if (this.item.gateway.length === 1) {
      let {gateway_key} = this.item.gateway[0];
      this.paymentMethod = gateway_key || "";
    }

    // get list gbp card
    if (!this.rpId && !this.eUid) return;
    await store.dispatch(GET_CARD_TOKEN, {pbid: this.rpId, euid: this.eUid});

    // select first card
    this.selectCard(this.gbp.cards[0] || null);
  },
  methods: {
    async createTransaction() {
      let errorMessage = this.validateForm(this.form);

      if (errorMessage) {
        return this.errorMessage = errorMessage;
      } else {
        this.errorMessage = {};
      }

      if (this.isLoading) return;
      this.isLoading = true;

      if (this.paymentMethod === "kbank_qr_code") {
        let result = await store.dispatch(GENERATE_TRANSACTION, {
          "unique_id": this.item.unique_id,
          "name": this.user.name,
          "phone": this.user.phone,
          "address": this.user.address,
          "email": this.user.email,
          "channel": this.originalName,
          "rp": this.rpId,
          "qty": this.qty
        });
        this.isLoading = false;
        if (result.status === 'yes') {
          await this.$router.push('/payment')
        } else {
          return Swal.fire(
              {
                html: result.error || 'ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn-primary'
                }
              }
          );
        }
      } else if (this.paymentMethod === "gbp_qr_code") {
        let result = await store.dispatch(GENERATE_TRANSACTION_GBP, {
          "unique_id": this.item.unique_id,
          "name": this.user.name,
          "phone": this.user.phone,
          "address": this.user.address,
          "email": this.user.email,
          "channel": this.originalName,
          "rp": this.rpId,
          "qty": this.qty
        });
        this.isLoading = false;
        if (result.status === 'yes') {
          await this.$router.push('/payment')
        } else {
          return Swal.fire(
              {
                html: result.error || 'ไม่สามารถทำรายการได้ กรุณาลองใหม่อีกครั้ง',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn-primary'
                }
              }
          );
        }
      } else if (this.paymentMethod === 'gbp_credit_card') {
        await this.$router.push('/credit-card')
      }
    },

    validateForm() {
      let validateRule = null;
      let errMessageList = null;

      validateRule = {
        paymentMethod: {
          presence: {
            allowEmpty: false,
            message: "^กรุณาเลือกวิธีการชำระเงิน"
          },
        },
      };

      errMessageList = validate(
          {
            paymentMethod: this.paymentMethod,
          }, validateRule);

      if (errMessageList) {
        const error = {};
        for (const [key, value] of Object.entries(errMessageList)) {
          error[key] = value[0];
        }
        return error;
      } else {
        return false;
      }
    },

    selectCard(card) {
      if (card) {
        if (card.card_token && card.card_number) {
          this.selectedCard = card;
          // set card token to store
          this.gbp.token = card.card_token;
        }
      } else {
        this.selectedCard = {};
        // remove card token in store
        this.gbp.token = "";
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/colors";

label {
  width: 100%;
  font-size: 1rem;
}

.card-input-element + .card {
  height: calc(36px + 2 * 1rem);
  color: var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 10px;
  color: #000;
}

.card-input-element + .card:hover {
  cursor: pointer;
}

.card-input-element:checked + .card {
  border: 2px solid var(--primary);
  -webkit-transition: border 0.3s;
  -o-transition: border 0.3s;
  transition: border 0.3s;
}

.card-input-element:checked + .card::after {
  content: none;
  color: #afb8ea;
  font-family: "Material Icons";
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.credit-card {
  cursor: pointer;

  .list {
    border-radius: 10px;
  }

  .body {
    position: relative;
  }

  .radio {
    position: absolute;
    z-index: 1;
    top: 25px;
    left: 25px;
  }
}


</style>
